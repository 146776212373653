<template>
    <div class="stock-sell">
        <div class="stock-sell-content">
          <yun-header />
            <div class="stock-sell-body">
                <div class="left">
                    <!-- 药品动态进销存与医保数据动态平衡监测 -->
                    <div class="health-data">
                        <!-- 标题 -->
                        <div class="health-data-header">
                            <span>药品动态进销存与医保数据动态平衡监测</span>
                        </div>
                        <div class="health-echart" ref="healthEhart"></div>
                    </div>
                    <!-- 进销存趋势分析 -->
                    <div class="tendency">
                        <!-- 标题 -->
                        <div class="tendency-header">
                            <div class="header-left">
                                <span class="common-txt">进销存趋势分析</span>
                                <img src="../assets/regulation/yd.png" alt="" />
                            </div>
                            <div class="header-right">
                                <span class="common-txt">精确查询</span>
                                <img src="../assets/control/arrows.png" alt="" />
                            </div>
                        </div>
                        <!-- 本周 本月 本季 本年 -->
                        <div class="time">
                            <div class="tendency-time">
                                <span class="actived">本周</span>
                                <span>本月</span>
                                <span>本季</span>
                                <span>本年</span>
                            </div>
                        </div>
                        <div class="tendency-echart" ref="tendencyEchart"></div>
                    </div>
                </div>
                <div class="center">
                    <!-- 今日药品销售总量 库存药品总量 -->
                    <div class="sale-total">
                        <div class="sale-commonstyle">
                            <div class="left">
                                <span>1</span>
                                <span>今日药品销售总量</span>
                            </div>
                            <div class="right">
                                <span v-for="(item,index) in filterSaleDate" :key="index"> {{item}}</span>
                                <!-- <span> 0</span>
                                <span> 7</span>
                                <span> 3</span>
                                <span> 1</span>
                                <span> 3</span>
                                <span> 0</span> -->
                            </div>
                        </div>
                        <div class="sale-commonstyle">
                            <div class="left">
                                <span>2</span>
                                <span>库存药品总量</span>
                            </div>
                            <div class="right">
                               <span v-for="(item,index) in filterSaleTotal" :key="index"> {{item}}</span>
                            </div>
                        </div>
                    </div>
                    <!-- 库存药品数据分析 -->
                    <div class="InventoryDrugs">
                        <!-- 标题 -->
                        <div class="InventoryDrugs-header">
                            <span> 库存药品数据分析</span>
                            <img src="../assets/regulation/yd.png" alt="" />
                        </div>
                        <!-- 图表 -->
                        <div class="inventoryDrug-echart">
                            <div class="store">
                                <div class="left" ref="storeEchart"></div>
                                <span>库存结构数据分析</span>
                            </div>
                            <div class="unsalable">
                                <div class="right" ref="unsalableEchart"></div>
                                <span>药品滞销率</span>
                            </div>
                        </div>
                    </div>
                    <!-- 滞销/近效/过期药品品种分析 -->
                    <div class="multiple">
                        <!-- 标题 -->
                        <div class="multiple-header">
                            <span> 滞销/近效/过期药品品种分析</span>
                            <img src="../assets/regulation/yd.png" alt="" />
                        </div>
                        <!-- 图表 -->
                        <div class="multiple-echart" ref="multiple"></div>
                    </div>
                </div>
                <div class="right">
                    <!-- 全县库存储备分类分析 -->
                    <div class="inventory-carry">
                        <!-- 标题 -->
                        <div class="inventory-carry-header">
                            <span>全县库存储备分类分析</span>
                            <img src="../assets/regulation/yd.png" alt="" />
                        </div>
                        <!-- 图表 -->
                        <div class="inventory-echart" ref="inventory"></div>
                    </div>
                    <!-- 全县药品储备缺溢货调控预警 -->
                    <div class="stockout">
                        <!-- 标题 -->
                        <div class="stockout-header">
                            <span>全县药品储备缺溢货情况</span>
                            <img src="../assets/regulation/yd.png" alt="" />
                        </div>
                        <!-- 表格-->
                        <div class="violation-table">
                            <table>
                                <tr class="first">
                                    <td>序号</td>
                                    <td>药店名称</td>
                                    <td>药品类别</td>
                                    <td>当前库存</td>
                                    <td>状态</td>
                                    <td>提示状态</td>
                                </tr>
                                <tr class="second" v-for="(item, index) in tableList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.type }}</td>
                                    <td>{{ item.store }}</td>
                                    <td>
                                        <span v-if="item.warning == 1" style="color:#FE4018"
                                            >溢货</span
                                        >
                                        <span v-else-if="item.warning == 2" style="color:#FFC038"
                                            >近效期</span
                                        >
                                        <span v-else-if="item.warning == 3" style="color:#00D3F9"
                                            >滞销</span
                                        >
                                    </td>
                                    <td>
                                        <span v-if="item.stadus == 1" style="color:#1DCE3A"
                                            >已提示</span
                                        >
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import moment from "moment";
import {stockTotal,saleList} from "../api/yaojian";
import yunHeader from "@/views/yunHeader";
export default {
  components:{yunHeader},
    data() {
        return {
            health: null, //药品动态进销存与医保数据动态平衡监测
            tendency: null, //进销存趋势分析
            storePie: null, //库存结构数据分析
            unsalablePie: null, //药品滞销率
            storePieList: [
                { value: 87.6, name: "正常" },
                { value: 10.4, name: "近效期" },
                { value: 2, name: "过期" },
                { value: 0, name: "其他" },
            ],
            multiple: null, // 滞销/近效/过期药品品种分析
            inventoryCarry: null, //全县库存储备分类分析
            tableList: [
                {
                    name: "康佰家中山路店",
                    type: "呼吸系统类",
                    store: 328,
                    warning: 1,
                    stadus: 1,
                },
                {
                    name: "九色鹿城北店",
                    type: "心血管类",
                    store: 126,
                    warning: 2,
                    stadus: 1,
                },
                {
                    name: "九色鹿城南店",
                    type: "镇痛消炎类",
                    store: 201,
                    warning: 3,
                    stadus: 1,
                },
                {
                    name: "浦江五和大药房",
                    type: "抗感染类",
                    store: 80,
                    warning: 1,
                    stadus: 1,
                },
                {
                    name: "浦江春来药房",
                    type: "感冒类",
                    store: 57,
                    warning: 1,
                    stadus: 1,
                },
            ],
         today: [moment().format("yyyy-MM-DD 00:00:01"), moment().format("yyyy-MM-DD 23:59:59")],
         total:0,
         saleData:0
        };
    },
    mounted() {
        this.healthEchart();
        this.tendencyEchart();
        this.storeEcharts();
        this.unsalableEcharts();
        this.multipleEcharts();
        this.inventoryEcharts();
        this.getSaleTotal()
    },
    methods: {
        // 今日药品销售总量
         getSaleTotal(){
          //总库存
            stockTotal({
                endTime: this.today[1],
                startTime: moment()
                    .add(-1, "day")
                    .format("yyyy-MM-DD 00:00:00"),
            }).then((rs) => {
                this.total = parseInt(rs.data[0].total);
                console.log(this.total,250)

            });
         let post={
          page:1,
          count:5,
          startTime:this.today[0],
          endTime:this.today[1],
          type:1
        }
         saleList(post).then(rs=>{
          this.saleData=rs.data.total
        })
         },

        //药品动态进销存与医保数据动态平衡监测
        healthEchart() {
            this.health = echarts.init(this.$refs.healthEhart);
            const option = {
                legend: {
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        color: "#fff",
                        fontSize: 18,
                    },
                    left: "3%",
                },
                grid: {
                    left: "10%",
                    right: "4%",
                    bottom: "10%",
                },

                xAxis: [
                    {
                        type: "category",
                        data: ["06-01", "06-02", "06-03", "06-04", "06-05", "06-06", "06-07"],
                        axisLabel: {
                            fontSize: 16,
                            color: "#EEEEEE",
                            fontWeight: 400,
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        axisLabel: {
                            fontSize: 16,
                            color: "#EEEEEE",
                            fontWeight: 400,
                        },
                        splitLine: {
                            lineStyle: {
                                color: "#081d4b",
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: "购进",
                        type: "bar",
                        emphasis: {
                            focus: "series",
                        },
                        itemStyle: {
                            color: "#00D7FE",
                        },
                        data: [320, 332, 301, 334, 390, 330, 320],
                    },
                    {
                        name: "销售",
                        type: "bar",
                        itemStyle: {
                            color: "#0277FA",
                        },
                        emphasis: {
                            focus: "series",
                        },
                        data: [120, 132, 101, 134, 90, 230, 210],
                    },
                    {
                        name: "库存",
                        type: "bar",
                        itemStyle: {
                            color: "#C41DF3",
                        },
                        emphasis: {
                            focus: "series",
                        },
                        data: [220, 182, 191, 234, 290, 330, 310],
                    },
                ],
            };
            this.health.setOption(option);
        },
        // 进销存趋势分析
        tendencyEchart() {
            this.tendency = echarts.init(this.$refs.tendencyEchart);
            const color = ["#00D7FE", "#0277FA", "#C41DF3"];
            const option = {
                color: color,
                legend: {
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        color: "#fff",
                        fontSize: 18,
                    },

                    itemGap: 28,
                    icon: "rect",
                    left: "3%",
                    top: "0",
                },
                xAxis: {
                    type: "category",
                    data: ["06-01", "06-02", "06-03", "06-04", "06-06", "06-07"],
                    axisLabel: {
                        fontSize: 18,
                        color: "#FEFEFE",
                        fontWeight: 400,
                    },
                },
                yAxis: {
                    type: "value",
                    axisLabel: {
                        fontSize: 18,
                        color: "#FEFEFE",
                        fontWeight: 400,
                    },
                    splitLine: {
                        lineStyle: {
                            color: "#081d4b",
                        },
                    },
                },
                series: [
                    {
                        name: "购进",
                        data: [30, 40, 50, 100, 50],
                        type: "line",
                        lineStyle: {
                            color: "#00D7FE",
                        },

                        labelLine: {
                            show: false,
                            lineStyle: {
                                color: "#00D7FE",
                            },
                        },
                    },
                    {
                        name: "销售",
                        data: [60, 40, 20, 30, 40],
                        type: "line",
                        lineStyle: {
                            color: "#0277FA",
                        },

                        labelLine: {
                            lineStyle: {
                                color: "#04559d",
                            },
                        },
                    },
                    {
                        name: "库存",
                        data: [20, 40, 30, 60, 50],
                        type: "line",

                        lineStyle: {
                            color: "#C41DF3",
                        },
                        labelLine: {
                            lineStyle: {
                                color: "#04559d",
                            },
                        },
                    },
                ],
            };
            this.tendency.setOption(option);
        },
        // 库存结构数据分析
        storeEcharts() {
            this.storePie = echarts.init(this.$refs.storeEchart);
            const option = {
                color: ["#C41DF3", "#00D7FE", "#FBC902", "#0096FF"],
                legend: {
                    orient: "vertical",
                    right: 0,
                    top: "15%",
                    left: "40%",
                    itemWidth: 12,
                    itemHeight: 12,
                    itemGap: 20,
                    textStyle: {
                        color: "#FFFFFF",
                        fontSize: 20,
                        fontWeight: 400,
                    },
                    textStyle: {
                        rich: {
                            a: {
                                display: "block",
                                width: 100,
                                color: "#FFFFFF",
                                fontSize: 20,
                                fontWeight: 400,
                            },
                            b: {
                                color: "#C41DF3",
                                fontSize: 20,
                                fontWeight: 400,
                            },
                            c: {
                                color: "#00D7FE",
                                fontSize: 20,
                                fontWeight: 400,
                            },
                            d: {
                                color: "#FBC902",
                                fontSize: 20,
                                fontWeight: 400,
                            },
                            e: {
                                color: "#0096FF",
                                fontSize: 20,
                                fontWeight: 400,
                            },
                        },
                    },
                    formatter: (name) => {
                        const currentIndex = this.storePieList.findIndex(
                            (item) => item.name == name
                        );
                        if (name == "正常") {
                            return `{a|${name}}{b|${this.storePieList[currentIndex].value}%}`;
                        } else if (name == "近效期") {
                            return `{a|${name}}{c|${this.storePieList[currentIndex].value}%}`;
                        } else if (name == "过期") {
                            return `{a|${name}}{d|${this.storePieList[currentIndex].value}%}`;
                        } else {
                            return `{a|${name}}{e|${this.storePieList[currentIndex].value}%}`;
                        }
                    },
                },
                series: [
                    {
                        name: "风险处置分析",
                        type: "pie",
                        radius: ["30%", "50%"],
                        center: ["20%", "50%"],
                        label: {
                            show: false,
                            position: "center",
                        },
                        data: this.storePieList,
                    },
                ],
            };
            this.storePie.setOption(option);
        },
        //药品滞销率
        unsalableEcharts() {
            this.unsalablePie = echarts.init(this.$refs.unsalableEchart);
            const option = {
                series: [
                    {
                        name: "滞药销售占比",
                        type: "pie",
                        radius: ["55", "45"],
                        center: ["50%", "45%"],
                        label: {
                            position: "center",
                            color: "#fff",
                            fontSize: 24,
                            fontWeight: "bold",
                        },
                        data: [
                            {
                                value: 100,
                                name: "20.7%",
                                itemStyle: {
                                    color: "#0277fa",
                                },
                            },
                            {
                                value: 500,
                                name: "20.7%",
                                itemStyle: {
                                    color: "#07265f",
                                },
                            },
                        ],
                    },
                ],
            };
            this.unsalablePie.setOption(option);
        },
        // 滞销/近效/过期药品品种分析
        multipleEcharts() {
            this.multiple = echarts.init(this.$refs.multiple);
            const option = {
                legend: {
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        color: "#fff",
                        fontSize: 18,
                    },
                    right: "3%",
                },
                grid: {
                    left: "10%",
                    right: "4%",
                    bottom: "10%",
                },

                xAxis: [
                    {
                        type: "category",
                        data: ["阿莫西林", "顺尔宁", "爱乐维", "决明子", "玫瑰花"],
                        axisLabel: {
                            fontSize: 16,
                            color: "#EEEEEE",
                            fontWeight: 400,
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        axisLabel: {
                            fontSize: 16,
                            color: "#EEEEEE",
                            fontWeight: 400,
                        },
                        splitLine: {
                            lineStyle: {
                                color: "#081d4b",
                            },
                        },
                    },
                ],
                series: [
                    {
                        barWidth: 10,
                        name: "滞销",
                        type: "bar",
                        emphasis: {
                            focus: "series",
                        },
                        itemStyle: {
                            color: "#00D7FE",
                        },
                        data: [320, 332, 301, 334, 390, 330, 320],
                    },
                    {
                        barWidth: 10,
                        name: "近效",
                        type: "bar",
                        itemStyle: {
                            color: "#0277FA",
                        },
                        emphasis: {
                            focus: "series",
                        },
                        data: [120, 132, 101, 134, 90, 230, 210],
                    },
                    {
                        barWidth: 10,
                        name: "过期",
                        type: "bar",
                        itemStyle: {
                            color: "#FFC038",
                        },
                        emphasis: {
                            focus: "series",
                        },
                        data: [220, 182, 191, 234, 290, 330, 310],
                    },
                ],
            };
            this.multiple.setOption(option);
        },
        //全县库存储备分类分析
        inventoryEcharts() {
            this.inventoryCarry = echarts.init(this.$refs.inventory);
            const color = ["#00D7FE", "#0096FF"];
            const option = {
                color: color,
                legend: {
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        color: "#fff",
                        fontSize: 18,
                    },

                    itemGap: 28,
                    icon: "rect",
                    right: "3%",
                    top: "0",
                },
                xAxis: {
                    type: "category",
                    data: ["化学药", "中成药", "生物制品", "中药饮片", "otc", "处方药"],
                    axisLabel: {
                        fontSize: 18,
                        color: "#FEFEFE",
                        fontWeight: 400,
                        interval: 0,
                    },
                },
                yAxis: {
                    type: "value",
                    axisLabel: {
                        fontSize: 18,
                        color: "#FEFEFE",
                        fontWeight: 400,
                    },
                    splitLine: {
                        lineStyle: {
                            color: "#081d4b",
                        },
                    },
                },
                series: [
                    {
                        name: "库存",
                        data: [30, 40, 50, 100, 50, 60],
                        type: "line",
                        lineStyle: {
                            color: "#00D7FE",
                        },

                        labelLine: {
                            show: false,
                            lineStyle: {
                                color: "#00D7FE",
                            },
                        },
                        areaStyle: {
                            //区域填充样式
                            normal: {
                                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                                color: new echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "rgba(25,163,223,.3)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(25,163,223, 0)",
                                        },
                                    ],
                                    false
                                ),
                                shadowColor: "rgba(25,163,223, 0.5)", //阴影颜色
                                shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
                            },
                        },
                    },
                    {
                        name: "历史平均",
                        data: [60, 40, 20, 30, 40, 50],
                        type: "line",
                        lineStyle: {
                            color: "#0096FF",
                        },

                        labelLine: {
                            lineStyle: {
                                color: "#04559d",
                            },
                        },
                        areaStyle: {
                            //区域填充样式
                            normal: {
                                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                                color: new echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "rgba(25,163,223,.3)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(25,163,223, 0)",
                                        },
                                    ],
                                    false
                                ),
                                shadowColor: "rgba(25,163,223, 0.5)", //阴影颜色
                                shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
                            },
                        },
                    },
                ],
            };
            this.inventoryCarry.setOption(option);
        },
    },
    computed:{
        filterSaleTotal(){
          return  this.total.toString().split("")
        },
        filterSaleDate(){
          return  this.saleData.toString().split("")
        }
    }
};
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.stock-sell {
    width: 1920px;
    background-image: url("../assets/regulation/bg.jpg");
    height: 1080px;
    overflow: hidden;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .stock-sell-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .stock-sell-header {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 65px;
            width: 100%;
            background-image: url("../assets/regulation/bt.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            .stock-sell-header-back {
                cursor: pointer;
                position: absolute;
                left: 46px;
                top: 60%;
                transform: translateY(-50%);
                span {
                    font-size: 24px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #00d7fe;
                    line-height: 26px;
                    margin: 0 30px 0 10px;
                }
                img {
                    width: 23px;
                    height: 19px;
                }
            }
            .stock-sell-header-title {
                line-height: 38px;
                font-size: 40px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #ffffff;
                line-height: 26px;
            }
        }
        .stock-sell-body {
            flex: 1;
            display: flex;
            margin-top: 15px;
            padding: 0 20px;
            gap: 15px;
            .left {
                flex: 1;
                .health-data {
                    display: flex;
                    flex-direction: column;
                    padding: 20px;
                    height: 483px;
                    background-image: url("../assets/stockSell/left-one.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    .health-data-header {
                        display: flex;
                        align-items: center;
                        span {
                            line-height: 27px;
                            font-size: 28px;
                            font-family: Source Han Sans CN;
                            font-weight: bold;
                            color: #00d7fe;
                        }
                    }
                    .health-echart {
                        flex: 1;
                        margin-top: 20px;
                    }
                }
                .tendency {
                    position: relative;
                    margin-top: 14px;
                    height: 474px;
                    display: flex;
                    flex-direction: column;
                    padding: 20px;
                    background-image: url("../assets/stockSell/left-two.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    .tendency-header {
                        display: flex;
                        justify-content: space-between;
                        .header-left {
                            display: flex;
                            align-items: center;
                            span {
                                line-height: 27px;
                                font-size: 28px;
                                font-family: Source Han Sans CN;
                                font-weight: bold;
                                color: #00d7fe;
                                margin-right: 20px;
                            }
                            img {
                                width: 95px;
                                height: 10px;
                            }
                        }
                        .header-right {
                            display: flex;
                            align-items: center;
                            span {
                                line-height: 22px;
                                font-size: 24px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                color: #00d7fe;
                                margin-right: 8px;
                            }
                            img {
                                width: 23px;
                                height: 21px;
                            }
                        }
                    }
                    .time {
                        position: absolute;
                        right: 20px;
                        top: 69px;
                        display: flex;
                        justify-content: flex-end;
                        .tendency-time {
                            width: 204px;
                            height: 24px;
                            border: 1px solid #0277fa;
                            border-radius: 4px;
                            display: flex;
                            align-items: center;
                            span {
                                flex: 1;
                                height: 100%;
                                border-right: 1px solid #0277fa;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 18px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                color: #0096ff;
                                &:last-child {
                                    border: none;
                                }
                            }
                            .actived {
                                color: #eeeeee;
                                background: #0277fa;
                            }
                        }
                    }
                    .tendency-echart {
                        flex: 1;
                        margin-top: 24px;
                    }
                }
            }
            .center {
                flex: 1;
                .sale-total {
                    height: 134px;
                    width: 100%;
                    margin-bottom: 21px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .sale-commonstyle {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background-image: url("../assets/stockSell/center-one.png");
                        height: 56px;
                        background-size: contain;
                        background-repeat: no-repeat;
                        padding-left: 20px;
                        .left {
                            display: flex;
                            align-items: center;
                            box-sizing: border-box;
                            span {
                                &:first-child {
                                    line-height: 20px;
                                    font-size: 26px;
                                    font-family: Source Han Sans CN;
                                    font-weight: bold;
                                    color: #ffffff;
                                    margin-right: 30px;
                                }
                                &:last-child {
                                    line-height: 25px;
                                    font-size: 26px;
                                    font-family: Source Han Sans CN;
                                    font-weight: bold;
                                    color: #ffffff;
                                    white-space: nowrap;
                                }
                            }
                        }
                        .right {
                            flex: 1;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            span {
                                display: block;
                                width: 50px;
                                height: 56px;
                                background-image: url("../assets/stockSell/numberBg.png");
                                background-size: 100% 100%;
                                background-repeat: no-repeat;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 36px;
                                font-family: Source Han Sans CN;
                                font-weight: bold;
                                color: #ffc038;
                                margin-right: 3px;
                                &:last-child {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
                .InventoryDrugs {
                    display: flex;
                    flex-direction: column;
                    padding: 20px;
                    height: 328px;
                    background-image: url("../assets/stockSell/center-two.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    .InventoryDrugs-header {
                        display: flex;
                        align-items: center;
                        span {
                            font-size: 28px;
                            font-family: Source Han Sans CN;
                            font-weight: bold;
                            color: #00d7fe;
                            margin-right: 20px;
                            letter-spacing: 6px;
                        }
                        img {
                            width: 95px;
                            height: 10px;
                        }
                    }
                    .inventoryDrug-echart {
                        flex: 1;
                        display: flex;
                        .store {
                            position: relative;
                            width: 398px;
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: space-between;
                            .left {
                                width: 100%;
                                height: 136px;
                            }
                            span {
                                line-height: 21px;
                                font-size: 22px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                color: #00d7fe;
                                line-height: 26px;
                            }
                            .store-list {
                                position: absolute;
                                right: 69px;
                                top: 14%;
                                display: flex;
                                flex-direction: column;
                                span {
                                    line-height: 15px;
                                    font-size: 20px;
                                    font-family: Source Han Sans CN;
                                    font-weight: bold;
                                    color: #c41df3;
                                    margin-bottom: 25px;
                                }
                            }
                        }
                        .unsalable {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: space-between;
                            .right {
                                width: 100%;
                                height: 136px;
                            }
                            span {
                                line-height: 21px;
                                font-size: 22px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                color: #00d7fe;
                                line-height: 26px;
                            }
                        }
                    }
                }
                .multiple {
                    display: flex;
                    flex-direction: column;
                    height: 474px;
                    margin-top: 14px;
                    padding: 20px;
                    background-image: url("../assets/stockSell/center-tree.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    .multiple-header {
                        display: flex;
                        align-items: center;
                        span {
                            font-size: 28px;
                            font-family: Source Han Sans CN;
                            font-weight: bold;
                            color: #00d7fe;
                            margin-right: 20px;
                            letter-spacing: 6px;
                        }
                        img {
                            width: 95px;
                            height: 10px;
                        }
                    }
                    .multiple-echart {
                        flex: 1;
                        margin-top: 20px;
                    }
                }
            }
            .right {
                flex: 1;
                .inventory-carry {
                    display: flex;
                    flex-direction: column;
                    height: 483px;
                    width: 100%;
                    padding: 20px;
                    background-image: url("../assets/stockSell/right-one.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    .inventory-carry-header {
                        display: flex;
                        align-items: center;
                        span {
                            font-size: 28px;
                            font-family: Source Han Sans CN;
                            font-weight: bold;
                            color: #00d7fe;
                            margin-right: 20px;
                            letter-spacing: 6px;
                        }
                        img {
                            width: 95px;
                            height: 10px;
                        }
                    }
                    .inventory-echart {
                        flex: 1;
                        margin-top: 10px;
                    }
                }
                .stockout {
                    display: flex;
                    flex-direction: column;
                    margin-top: 14px;
                    padding: 20px;
                    width: 100%;
                    height: 474px;
                    background-image: url("../assets/stockSell/right-two.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    .stockout-header {
                        display: flex;
                        align-items: center;
                        span {
                            font-size: 28px;
                            font-family: Source Han Sans CN;
                            font-weight: bold;
                            color: #00d7fe;
                            margin-right: 20px;
                            letter-spacing: 3px;
                        }
                        img {
                            width: 95px;
                            height: 10px;
                        }
                    }
                    .violation-table {
                        flex: 1;
                        margin-top: 30px;
                        overflow-y: auto;
                        table {
                            height: 100%;
                            width: 100%;
                            border-collapse: collapse;
                            .first {
                                height: 40px;
                                background-image: url("../assets/regulation/表头.png");
                                text-align: center;
                                td {
                                    line-height: 40px;
                                    font-size: 20px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 400;
                                    color: #ffffff;
                                    vertical-align: middle;
                                }
                            }
                            .second {
                                text-align: center;
                                height: 59px;
                                &:nth-child(2n + 1) {
                                    background: url("../assets/regulation/表单.png");
                                    background-size: 100% 100%;
                                    background-repeat: no-repeat;
                                }
                                td {
                                    line-height: 59px;
                                    font-size: 20px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 400;
                                    color: #ffffff;
                                    vertical-align: middle;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
